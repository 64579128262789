var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"am-afa-graffiti px",class:{ mobile: _vm.isMobile, cn: _vm.isCN }},[_c('v-header'),_c('img',{staticClass:"image01",attrs:{"src":_vm.isMobile
        ? _vm.isCN
          ? _vm.oss.am_afa_graffiti_kv_mobile
          : _vm.oss.am_afa_graffiti_kv_mobile_en
        : _vm.isCN
        ? _vm.oss.am_afa_graffiti_kv
        : _vm.oss.am_afa_graffiti_kv_en,"alt":"AM AFA GRAFFITI"}}),_c('div',{staticClass:"title01",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.title01'))}}),_c('div',{staticClass:"text01",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.text01'))}}),_c('div',{staticClass:"title02",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.title02'))}}),_c('div',{staticClass:"text02",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.text02'))}}),_c('div',{staticClass:"tips",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.tip01'))}}),_c('div',{staticClass:"title03",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.title03'))}}),_c('div',{staticClass:"text03",staticStyle:{"margin-bottom":"0"},domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.text03'))}}),_c('a',{staticClass:"button",attrs:{"href":_vm.isCN ? _vm.oss.am_afa_graffiti_zip : _vm.oss.am_afa_graffiti_zip_en,"target":"_blank"}},[_c('img',{staticClass:"do",attrs:{"src":_vm.isCN ? _vm.oss.am_afa_graffiti_download : _vm.oss.am_afa_graffiti_download_en,"alt":"AM AFA GRAFFITI"}})]),_c('div',{staticClass:"text04",staticStyle:{"margin-bottom":"0"},domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.text04'))}}),_c('a',{staticClass:"button",attrs:{"href":_vm.isCN
        ? 'https://www.angrymiao.com/dao/309/'
        : 'https://www.angrymiao.com/dao/311/',"target":"_blank"}},[_c('img',{staticClass:"image",attrs:{"src":_vm.isCN ? _vm.oss.am_afa_graffiti_upload : _vm.oss.am_afa_graffiti_upload_en,"alt":"AM AFA GRAFFITI"}})]),_c('br'),_c('br'),_c('div',{staticClass:"title04",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.title04'))}}),_c('div',{staticClass:"text05",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.text05'))}}),_c('div',{staticClass:"title05",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.title05'))}}),_c('div',{staticClass:"text06",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.text06'))}}),(_vm.isCN)?_c('div',{staticClass:"title06",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.title06'))}}):_vm._e(),(_vm.isCN)?_c('img',{staticClass:"image",staticStyle:{"width":"4rem","margin-bottom":"0.21rem"},attrs:{"src":_vm.oss.qr_code_am_afa,"alt":"AM AFA GRAFFITI"}}):_vm._e(),(_vm.isCN)?_c('div',{staticStyle:{"margin-bottom":"1rem","text-align":"center","font-size":"0.2rem"},domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.text07'))}}):_vm._e(),_c('div',{staticClass:"title06",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.title07'))}}),_c('div',{staticClass:"text06",domProps:{"innerHTML":_vm._s(_vm.$t('AMAfaGraffiti.text08'))}}),(!_vm.isCN)?_c('v-join-us',{attrs:{"src":_vm.oss.qr_code_am_afa}}):_vm._e(),_c('v-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }