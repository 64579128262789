




















































































import Vue from "vue";
import { mapState } from "vuex";
import "swiper/swiper-bundle.min.css";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss"
    })
  }
});
